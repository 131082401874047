
// TODO: i18next breaks AOS animations, but not clue why.
// An AOS.refresh() at the right time could be the fix. but it is not clear

import React from 'react';
import {Divider} from '@material-ui/core';
import { graphql } from 'gatsby';

import Minimal from '../layouts/Minimal';
import WithLayout from '../layouts/WithChildrenLayout';
import { Section, SectionAlternate } from '../components/organisms';

import {images} from "../components/MediaLib";
import { CallToAction, SideCoverContainer, MauticSubscribe } from "../views";
import {CompanyData} from "../content";


import {SEO} from "../components/seo";


const GetStartedPage = (props) => {
  // Translation temporarily disabled
  //const {t} = useTranslation();
  const t = (txt)=>txt;
  return (
    <WithLayout layout={Minimal}>
      <SEO title={t("Getting Started with Privazio")}/>
      <SideCoverContainer cover_image={images.coding}>
        <div>
          <CallToAction
            href={CompanyData.gitlab_url}
            action={t("Gitlab")}
            title={t("Join our Gitlab")}
            subtitle={t("Privazio is Alpha and needs contributors, reach out if you would like to help, get the repo.")}/>
        </div>
        <Divider/>
        <div>
          <MauticSubscribe
            title={t("Subscribe")}
            subtitle={t("Get progress updates via email.")}
            subscribe_action_url={CompanyData.mautic_subscribe_action_url}
          />
        </div>
      </SideCoverContainer>
    </WithLayout>
  )
};

export default GetStartedPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["translation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;